import { Component } from "react";
import { AiFillMail } from "react-icons/ai";
import { AiFillPhone } from "react-icons/ai";
class Contact extends Component {
  render() {
    return (
      <div className="contact">
        <a href="mailto: info@tekcloudsoul.co.uk">
          <AiFillMail />
          <span> info@tekcloudsoul.co.uk</span>
        </a>

        <a href="tel:+916005561860">
          <AiFillPhone />
          <span>+916005561860</span>
        </a>
      </div>
    );
  }
}

export default Contact;
